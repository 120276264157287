<template>
    <div>
        <Navbar :changeBgColor="true" :mdHidden="true"></Navbar>

        <div class="relative min-h-screen flex">
            <div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 bg-white">
                <div class="sm:w-1/2 xl:w-3/5 h-full hidden md:flex flex-auto items-center justify-center p-10 overflow-hidden bg-purple-900 text-white bg-no-repeat bg-cover relative"
                    :style="reservationCustomerSettings && reservationCustomerSettings.setting.welcomeSectionBackgroundImage != '' ? { backgroundImage: 'url(' + reservationCustomerSettings.setting.welcomeSectionBackgroundImage + ')' } : ''"
                    >
                    <div class="absolute bg-gradient-to-b from-indigo-600 to-blue-300 opacity-75 inset-0 z-0"></div>
                    <div class="w-full  z-10">
                        <a href="#" class="">
                            <span class="text-3xl font-black leading-none text-white select-none logo">
                                <img class="mx-auto" :src="reservationCustomerSettings ? reservationCustomerSettings.logo : ''" style="max-width: 150px;" />
                            </span>
                        </a>

                        <h1 v-if="reservationCustomerSettings.setting.welcomeSectionTitle" class="mx-6 mt-4 mt-1 text-xl text-3xl font-black text-center text-white lg:text-5xl sm:text-center sm:mx-0">
                            {{ reservationCustomerSettings.setting.welcomeSectionTitle }}                 
                        </h1>
                        <h1 v-else class="mx-6 mt-4 mt-1 text-xl text-3xl font-black text-center text-white lg:text-5xl sm:text-center sm:mx-0">
                            {{ reservationCustomerSettings.companyName }}           
                        </h1>
        
                        <div class="mt-3 space-x-1 text-center" v-if="reservationCustomerSettings.setting.welcomeSectionEtoile">
                            <div class="inline-block" v-for="index in Number(reservationCustomerSettings.setting.welcomeSectionEtoile)" :key="index">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                                </svg>
                            </div>
                        </div>
                        
                        <p v-if="reservationCustomerSettings.setting.welcomeSectionSubTitle" class="my-6 text-xl font-normal text-center text-gray-200">
                        {{ reservationCustomerSettings.setting.welcomeSectionSubTitle }}
                        </p>
                        <p v-else class="my-6 text-xl font-normal text-center text-gray-200">
                        {{ reservationCustomerSettings.companyAddress+', '+reservationCustomerSettings.country }}
                        </p>
                    </div>

                    <!---remove custom style-->
                    <ul class="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
                                    
                <div class="md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full w-2/5 xl:w-2/5 p-8  md:p-10 lg:p-14 sm:rounded-lg md:rounded-none bg-white">
                    <div class="w-full space-y-8" style="margin-top: 5rem;">
                        <div class="text-center">

                            <h2 class="mt-10 text-3xl font-bold text-gray-900">
                                Confirmation de réservation N° {{ orderRef }}
                            </h2>

                            <div class="text-left text-xl text-gray-700 hover:text-gray-600 text-sm mt-10" rel="noopener noreferrer">
                                <h6 class="text-lg">Bonjour,</h6>
                                <p class="text-lg mt-4">Votre réservation a bien été prise en compte et nous vous remercions de votre confiance.</p>
                                <p class="text-lg mt-4">Vous recevrez un email récapitulatifs de votre réservation. En règle générale, vous recevrez un email de confirmation dans les 15 minutes suivant votre réservation.</p>
                            </div>
                        </div>
                        
                        <div class="mt-8 space-y-6">
                            <router-link :to="{ path: `/${this.$route.params.slug}/welcomepage` }" class="flex justify-center items-center px-6 py-3 border border-transparent rounded-full shadow-sm text-sm font-semibold text-white bg-primary hover:opacity-75" rel="noopener noreferrer">
                                Retour vers la page d'accueil
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { Validator } from 'vee-validate';
import { mapState } from "vuex";
const Navbar = () => import("@/views/welcome-page/Navbar.vue");

const dictionary = {
  en: {
    firstname: {
      required: 'required'
    }
  },
  fr: {
    firstname: {
      required: 'obligatoire'
    }
  }
};

Validator.localize(dictionary)
Validator.localize('fr')

import moduleSettingManagement from '@/store/settings/moduleSettingManagement.js'

export default {
	data () {
		return {
			//
		}
	},
	computed: {
		reservationCustomerSettings() {
			return this.$store.state.moduleSetting.customerSetting
		},
    orderRef() {
        return this.$route.params.ref
    }
  },
  methods: {

  },
  components: {
    Navbar
	},
	mounted() {
		this.$store.commit('eCommerce/UPDATE_WIZARD_STEPS_BAR', 2)
	},
	created () {
		if (!moduleSettingManagement.isRegistered) {
		this.$store.registerModule('settingManagement', moduleSettingManagement)
		moduleSettingManagement.isRegistered = true
		}
	}

}
</script>

<style lang="css">

@import "https://unpkg.com/tailwindcss@2.0.2/dist/tailwind.min.css";

/*remove custom style*/
.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;  
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}
 
.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}
 
.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}
 
.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}
 
.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}
 
.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}
 
.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}
 
.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}
 
.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}
 
.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}
  @keyframes animate {
 
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
 
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
 
}

</style>